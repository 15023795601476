.formWrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 35px 0;
  float: left;
  width: 100%;

  .carImage {
    display: inline-block;
    position: absolute;
    height: 100vh;
    width: 100%;
    content: '';

    right: 0;
    top: 0;

    background-image: url('../../assets/images/new-juke-front.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 45% auto;

    @media only screen and (max-width: 1200px) {
      background-position: top right;
      background-size: 50% auto;
    }

    @media only screen and (max-width: 1080px) {
      display: none;
    }
  }

  .container {
    position: relative;
    max-width: 1240px;
    text-align: left;
    margin: 0 auto;
    width: 95%;

    .formDetails{
      display: block;
      min-height: 50vh;
      max-width: 640px;
      float: left;
      width: 100%;

      @media only screen and (max-width: 1200px) {
        max-width: 460px;
      }

      h2{
        font-family: 'Nissan Brand';
        line-height: 24px;
        font-weight: bold;
        font-size: 28px;
        color: #000;

        display: inline-block;
        margin: 25px 0;
      }

      h3{
        display: inline-block;
        text-align: left;
        width: 100%;
        float: left;

        margin: 0 0 25px;
        padding: 0;

        font-family: 'Nissan Brand';
        line-height: 24px;
        font-weight: 300;
        font-size: 18px;
        color: #000;

        @media only screen and (max-width: 460px) {
          line-height: normal;
          font-weight: normal;
          font-size: 16px;
        }

        b{
          font-weight: bold;
          color: #c3092e;
        }

        a{
          color: #c3092e;
        }
      }

      > a{
        display: inline-block;
        background: #c3092e;
        font-size: 14px;
        color: #fff;
        clear: both;
        float: left;

        padding: 10px 25px;
        margin: 0 0 15px;

        &:hover{
          text-decoration: none;
          background: darken(#c3092e, 5%);
        }
      }
    }

    .formContainer{
      display: block;
      max-width: 640px;
      width: 100%;
      float: left;
      clear: both;
    }
  }
}
