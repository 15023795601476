@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'vendors/fontawesome/font-awesome.scss';
@import 'fonts/nissan';

html, body {
  height: 100%;

  // Fix for Material Icons on IE 11
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';

  img {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
            supported by Chrome and Opera */
  }

  *{
    &::-moz-selection {
      background: #c3092e;
      color: #fff;
    }

    &::selection {
      background: #c3092e;
      color: #fff;
    }
  }
}

#root {
  height: 100%;
}

.App {
  font-family: 'Nissan Brand';
  position: relative;
  height: 100%;

  &:before{
    background-image: url(../assets/images/background.png);
    background-position: top right;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: auto 100%;

    display: inline-block;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    content: '';
    left: 0;
    top: 0;

    @media only screen and (max-width: 1080px) {
      opacity: 0.2;
    }
  }

  
  img{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
            supported by Chrome and Opera */
  }

  .container{
    position: relative;
    max-width: 1240px;
    text-align: left;
    margin: 0 auto;
    width: 95%;
  }

  .center-align{
    display: table-cell;
    vertical-align: middle;
  }

  a{
    color: #c3092e;
  }
}
