.header{
  .logo{
    display: inline-block;
    max-width: 140px;
    float: left;
    padding: 0;
    margin: 0;

    img{ width: 100%; }

    @media only screen and (max-width: 640px) {
      max-width: 100px;
    }

    @media only screen and (max-width: 460px) {
      max-width: 75px;
    }
  }

  .container{
    position: relative;
    max-width: 1240px;
    text-align: left;
    margin: 0 auto;
    width: 95%;

    h1{
      font-family: 'Nissan Brand';
      display: inline-block;
      margin: 25px 0 0 25px;

      line-height: 50px;
      font-weight: bold;
      font-size: 55px;
      float: left;

      span{
        display: block;
        color: #c3092e;
      }

      small{
        display: block;
        font-weight: bold;
        font-size: 35px;
      }

      @media only screen and (max-width: 640px) {
        line-height: 30px;
        font-size: 35px;

        small{
          font-size: 25px;
        }
      }

      @media only screen and (max-width: 460px) {
        margin: 15px 0 0 15px;
        line-height: 25px;
        font-size: 30px;

        small{
          font-size: 20px;
        }
      }

      @media only screen and (max-width: 340px) {
        line-height: 20px;
        margin-top: 32px;
        font-size: 26px;

        small { font-size: 16px; }
      }
    }
  }
}