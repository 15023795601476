.footer{
  display: inline-block;
  width: 100%;
  float: left;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.8+80,1+100 */
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 80%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 80%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 80%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */


  .container{
    position: relative;
    max-width: 1240px;
    text-align: left;
    margin: 0 auto;
    width: 95%;

    h2{
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: 50px;
      font-weight: bold;
      text-align: left;
      color: #000;
      width: 80%;

      b{
        background: #c3092e;
        padding: 0 10px;
        color: #fff;

        @media only screen and (max-width: 1060px) {
          margin-bottom: 5px;
          padding: 5px 10px;
          display: block;
        }

        @media only screen and (max-width: 460px) {
          span{
            display: block;
          }
        }
      }

      @media only screen and (max-width: 1000px) {
        text-align: center;
        width: 100%;
      }

      @media only screen and (max-width: 640px) {
        font-size: 21px;
      }
    }

    img{
      position: relative;
      margin-bottom: -50px;
      z-index: 1;
      width: 50%;

      @media only screen and (max-width: 1000px) {
        margin: 0 auto;
        display: block;

        max-width: 720px;
        width: 100%;
      }
    }
  }

  .campaignDetails{
    display: inline-block;
    position: relative;
    width: 50%;
    top: 50px;

    h3{
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #000;

      span{
        display: block;

        &.greyedOut{
          font-size: 21px;
          color: #cacaca;
        }
      }

      b{
        background: #c3092e;
        padding: 0 10px;
        color: #fff;
      }
    }

    @media only screen and (max-width: 1560px) {
      width: 35%;
    }

    @media only screen and (max-width: 1100px) {
      width: 40%;
    }

    @media only screen and (max-width: 1000px) {
      text-align: center;
      width: 100%;
      left: auto;
      top: 0;
    }

    p{
      line-height: 24px;
      font-weight: 300;
      font-size: 18px;
    }
  }

  .bottomFooter{
    display: inline-block;
    background: #c3092e;
    position: relative;
    padding: 25px 0;
    float: left;
    width: 100%;

    h3{
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      color: #fff;

      width: 50%;
      float: right;

      @media only screen and (max-width: 1560px) {
        width: 50%;
      }

      @media only screen and (max-width: 1000px) {
        text-align: center;
        font-size: 21px;
        width: 100%;
      }

      @media only screen and (max-width: 640px) {
        font-size: 18px;
      }
    }
  }
}