.formWrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin: 35px 0;
  float: left;
  width: 100%;

  .carImage{
    display: inline-block;
    position: absolute;
    height: 100vh;
    width: 100%;
    content: '';

    right: 0;
    top: 0;

    background-image: url('../../assets/images/new-juke-front.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 45% auto;

    @media only screen and (max-width: 1200px) {
      background-position: top right;
      background-size: 50% auto;
    }

    @media only screen and (max-width: 1080px) {
      display: none;
    }
  }

  .container {
    position: relative;
    max-width: 1240px;
    text-align: left;
    margin: 0 auto;
    width: 95%;

    .formDetails{
      display: block;
      max-width: 640px;
      float: left;
      width: 100%;

      @media only screen and (max-width: 1200px) {
        max-width: 460px;
      }

      h2{
        font-family: 'Nissan Brand';
        line-height: 24px;
        font-weight: bold;
        font-size: 28px;
        color: #000;

        display: inline-block;
        margin: 25px 0;
      }

      h3{
        display: inline-block;
        text-align: left;
        width: 100%;
        float: left;

        margin: 0 0 25px;
        padding: 0;

        font-family: 'Nissan Brand';
        line-height: 24px;
        font-weight: 300;
        font-size: 18px;
        color: #000;

        @media only screen and (max-width: 460px) {
          line-height: normal;
          font-weight: normal;
          font-size: 16px;
        }

        b{
          font-weight: bold;
          color: #c3092e;
        }
      }
    }

    .formContainer{
      display: block;
      max-width: 640px;
      width: 100%;
      float: left;
      clear: both;

      &.isLoading {
        pointer-events: none;
        position: relative;
        opacity: 0.5 !important;

        &:after {
          background-image: url('../../assets/images/loader.gif');
          background-position: center center;
          background-repeat: no-repeat;
          background-color: #fff;
          background-size: 75%;

          display: inline-block;
          pointer-events: none;
          position: absolute;
          height: 64px;
          width: 64px;
          content: '';

          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;

          left: calc((100% - 64px) / 2);
          top: calc((100% - 64px) / 2);

          -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.form {
  padding: 10px 0 25px;
  display: inline-block;
  float: left;
  width: 100%;

  label, .group{
    display: inline-block;
    float: left;
    width: 49%;

    &.selectable{
      position: relative;

      &:after{
        display: inline-block;
        pointer-events: none;
        position: absolute;
        content: '';
        z-index: 1;

        background-image: url('../../assets/images/chevron.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 65%;
        background-color: #fff;

        height: 44px;
        width: 25px;

        right: 1px;
        top: 25px;
      }
    }

    &.city{
      @media only screen and (min-width: 460px) {
        margin-top: 25px;
      }
    }

    &:nth-child(even)  { float: right; }
    &:nth-child(2n+1) { clear: both; }

    @media only screen and (max-width: 460px) {
      &:not(.control){
        width: 100%;
      }
    }

    p{
      font-family: 'Nissan Brand';
      text-transform: uppercase;
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      color: #000;
      width: 100%;
      margin: 0;

      @media only screen and (max-width: 460px) {
        span{ display: block; }
      }
    }

    input, select{
      border: 1px solid #dadada;
      outline-color: #c3092e;
      display: inline-block;
      background: #fff;
      font-size: 16px;
      padding: 10px;
      width: 100%;
      box-shadow: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-clip: padding-box;
    }

    .error{
      display: inline-block;
      font-size: 13px;
      margin: 5px 0 0;
      color: #c3092e;
      width: 100%;

      &:before{
        display: inline-block;
        margin-right: 7px;
        height: 19px;
        width: 12px;

        content: '';
        float: left;

        background-image: url('../../assets/images/warning.svg');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
  }

  .group{
    display: inline-block;

    > div{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
    }

    label{
      width: calc(33.3% - 10px);
      float: left;

      &:nth-child(2n+1){ clear: none; }
    }
  }

  button[type="submit"]{
    display: inline-block;
    outline-color: #c3092e;
    background: #c3092e;
    cursor: pointer;
    border: none;
    float: right;
    clear: both;

    font-family: 'Nissan Brand';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    color: #fff;

    padding: 15px 35px;
    margin: 0;

    &:hover{
      background: darken(#c3092e, 5%);
    }

    @media only screen and (max-width: 330px) {
      text-align: center;
      width: 100%;
    }
  }
}

.fullwidth{ width: 100% !important; }

// Checkboxes and radios
.control {
  display: inline-block;
  position: relative;
  min-width: 16px;
  cursor: pointer;

  padding: 0 0 0 30px;
  margin: 15px 0;

  font-size: 16px;
  color: #838383;

  p{
    font-family: 'Nissan Brand';
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    color: #000;
    width: 100%;
    margin: 0;

    small{
      font-weight: 700;
      margin: 10px 0 0;
      display: block;
    }
  }

  h3 {
    position: relative;
    margin-bottom: 5px;
    top: -2px;
  }

  input {
    position: absolute;
    display: none !important;
    z-index: -1;
    opacity: 0;
  }
}

.controlIndicator {
  background: none;
  border: 2px solid #d4d4d4;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 2px;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

.control input.prevChecked ~ .controlIndicator:after {
  display: block;
}

.control input.prevChecked ~ .controlIndicator {
  border-color: #c3092e !important;
  border: none;
  background: #c3092e !important;
}

.controlRadio .controlIndicator {
  border-radius: 50%;

  &:after{
    display: inline-block;
    background: #fff;
    height: 6px;
    width: 6px;

    top: calc((100% - 6px)/2);
    left: calc((100% - 6px)/2);

    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }
}
.control:hover input ~ .controlIndicator,
.control input:focus ~ .controlIndicator {
  background: #f1f1f1;
}
.control input:checked ~ .controlIndicator {
  border-color: #c3092e !important;
  border: none;
  background: #c3092e !important;
}

.control input:disabled ~ .controlIndicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control input:checked ~ .controlIndicator:after {
  display: block;
}

.controlCheckbox .controlIndicator:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.controlCheckbox input:disabled ~ .controlIndicator:after {
  border-color: #7b7b7b;
}
.control--radio .controlIndicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .controlIndicator:after {
  background: #7b7b7b;
}
